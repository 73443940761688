

<div class="toolbar-wrapper" [ngClass]="{ sticky: stickyNav, navHidden: navHidden && !mobileMenuIsOpened }"
  [class.sticky]="mobileMenuIsOpened">
  <p-toolbar>
    <div class="p-toolbar-container">
      <div class="p-toolbar-group-start">
        <button (click)="toggleSidebarEvent()" pButton pRipple class="c-button icon-only mr-16-px">
          <img src="./../../../../assets/images/navigation/toggle.svg" alt="toggle-sidebar">
        </button>
      </div>
      <div class="p-toolbar-group-center">
      
      <app-search-input  [control]="searchControl" [globalSearch]="true" [name]="'global-patients-search'" [placeholder]="'Search patients name'" [desktopOnly]="true"></app-search-input>
      </div>
      <div class="p-toolbar-group-end">

        <ng-container>
          <div class="nav-icons">

            <div class="icons">
              <button pButton pRipple  (click)="requestPermission();"
              class="c-button icon-only icon-with-badge-btn tertiary w-auto">
              <app-switch-control (click)="$event.stopPropagation()" [classess]="'mb-unset'" [small]="true" [name]="'notifications'" 
              [control]="notificationsControl">
              </app-switch-control>
              <i class="pi pi-bell icon-with-badge ml-4-px" ></i>
            </button>
            @if(hasUnreadMessages$ | async; as hasUnreadMessages){
              <button [routerLink]="['/admin/chats']" pButton pRipple
              class="c-button icon-only icon-with-badge-btn tertiary overflow-visible">
              <i class="pi pi-comments icon-with-badge"  pBadge [badgeStyleClass]="'visible'" [value]="unreadMessagesCount$ | async"></i>
            </button>
            } @else {
              <button [routerLink]="['/admin/chats']" pButton pRipple
              class="c-button icon-only icon-with-badge-btn tertiary">
              <i class="pi pi-comments icon-with-badge"  pBadge [badgeStyleClass]="'hidden'" [value]="unreadMessagesCount$ | async"></i>
            </button>
            }
           
          
     
            </div>
        
            @if(userProfile$ | async; as userProfile){
              <button class="profile-menu-wrapper no-styles-btn" (click)="menu.toggle($event)" pButton pRipple>
                <app-user-image-block [maxNameWidth]="'160px'"
                [firstName]="userProfile.firstName" 
                [lastName]="userProfile.lastName"
                [role]="userProfile.role"
                [subtitle]="userProfile.role"
                [photoUrl]="userProfile.photoUrl"
               [showActions]="false" [showTitle]="true" [showSubtitle]="true"></app-user-image-block>

                <div class="expand-icon-wrapper ml-16-px">
                  <img src="./../../../../assets/images/icons/More.svg" alt="">
                </div>
                <p-menu [styleClass]="'user-p-menu'" #menu [model]="userMenu" [appendTo]="'body'" [popup]="true"></p-menu>
              </button>

            } @else {
              <div class="user-dropdown-loader">
                <p-skeleton shape="circle" height="40px" width="40px" styleClass="mr-2-"></p-skeleton>
                <div class="content">
                  <div class="inner-flex-container">
                    <p-skeleton width="160px" height="16px" [styleClass]="'mb-4-px'" ></p-skeleton>
                    <p-skeleton width="40px" height="14px" ></p-skeleton>
                  </div>
                  <p-skeleton shape="circle" width="20px" height="20px"></p-skeleton>
                </div>
              </div>
      

            }
             
         


          </div>
        </ng-container>

      </div>
    </div>

  </p-toolbar>

  <div class="mobile-toolbar">
    <div class="container">
      <app-search-input [control]="searchControl" [globalSearch]="true" [name]="'global-search2'" [placeholder]="'Search patients name'" [mobileOnly]="true"></app-search-input>
    </div>
  </div>
</div>


<p-sidebar styleClass="user-mobile-sidebar-menu" [(visible)]="userMobileMenuVisible" position="right">
  <ng-template pTemplate="header">Account</ng-template>
  <ng-template pTemplate="content">
    <app-user-inner-menu (closeUserSidenavEvent)="userMobileMenuVisible = false" [inMobileSidenav]="true"></app-user-inner-menu>
  </ng-template>
</p-sidebar>


 